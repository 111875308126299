.multi-chart {
  .bar-line-svg, .stacked-svg, .multiline-svg {
    .x-axis {
      .domain {
        stroke: none;
      }
    }
  }
  .line {
    fill: none;
  }
  .dot {
    fill: #fff;
    cursor: pointer;
  }
}

.multiline-brush, .barline-brush {
  .line {
    fill: none;
    stroke: steelblue;
    stroke-width: 2px;
  }
  
  .tick line {
    opacity: 0.2;
  }
  
  .zoom {
    cursor: move;
    fill: none;
    pointer-events: all;
  }
  
  .brush {
    pointer-events: all;
  }
  
  .right,
  .left {
    stroke: #000;
    stroke-width: 0.4;
    fill: #fff;
    fill-opacity: 0.7;
  }
  
  .selection {
    fill-opacity: 0;
    fill: none;
    stroke: none;
  }
  
  .tooltip {
    font-size: 10px;
    padding: 6px;
    border-radius: 3px;
    background-color: hsla(0, 0%, 47%, 0.6);
    color: #fff;
    pointer-events: none;
  
    .square {
      width: 10px;
      height: 10px;
      margin-right: 4px;
      margin-top: -2px;
      display: inline-block;
      vertical-align: middle;
      border: 1px solid #fff;
    }
  }  
}

.tooltip {
  font-size: 10px;
  padding: 10px;
  border-radius: 3px;
  background-color: rgba(18, 18, 18, 0.849);
  color: #fff;
  pointer-events: none;

  ul {
    margin: 0px;
  }
  
  .square {
    width: 10px;
    height: 10px;
    padding: 5px;
    margin-right: 4px;
    margin-top: -4px;
    display: inline-block;
    vertical-align: middle;
    border: 1px solid #fff;
  }
}
$black: #181f28;
$lightblack: #181f2821;
$white: #ffffff;
$gray: #f5f5f5;

.rs-table-container {
  margin-top: 6px;
}

.rs-table-main-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 24px;
  width: 100%;
  background-color: $white;
  border-bottom: 1px solid #f5f5f5;
  
  .view {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;    
  }

  .image-wrapper {
    width: 24px;
    height: 24px;
    margin-left: .5rem;
  }

  .title {
    color: $black;
    font-size: 14px;
    text-align: center;
    // margin: auto 0;
    margin-left: .2rem;
    font-weight: bold;

    .first-letter {
      font-size: 16px;
    }
  }
}
.rs-table {
  padding: 0;
  font-size: 11px;

  .rs-table-row-header {
    color: #666;
    overflow: unset;

    .rs-table-cell {
      overflow: unset;

      .rs-table-cell-content {
        overflow: unset;
        white-space: unset;
      }
    }
  }

  .rs-table-row {
    transition: none;
    &.selected {
      background-color: #ffffe9;
      color: #6fa7d9;
      font-weight: bold;
    }
    // &:not(.rs-table-row-header):hover {
    //   background: none;
    // }

    .rs-table-cell-group-fixed-left,
    .rs-table-cell-group-fixed-right,
    .rs-table-cell {
      background: none;
      transition: none;
    }

  }

  .header-cell-group {
    .rs-table-cell-content {
      padding: 0!important;
      font-size: 11px;
    }
    
    &-first-cell {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 53px;
      width: 100%;
      background-color: $white;
      cursor: pointer;
    }
    &-first-cell-subtitle {
      display: block;
      width: 100%;
      background-color: $white;
      color: $black;
      text-align: left;
      font-weight: 700;
      padding: 0px 10px;
      height: 18px;
      border-bottom: 1px solid $gray;
    }

    &-title {
      font-weight: 700;
      background-color: $white;
      border-right: 2px solid $gray;
      height: 18px;
    }
    &-subtitle {
      display: flex;
      background-color: $black;
      color: $white;
      border-right: 2px solid $gray;
      span {
        width: 100%;
        height: 18px;
      }

      &.total {
        background-color: $white;
        color: $black;
        font-weight: bold;
        border-bottom: 2px solid $gray;
        height: 18px;
      }

      &-item {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &:hover {
        .cog-menu-item {
          display: inline-block!important;
        }
      }
    }

    .rs-table-cell-header-sort-wrapper {
      float: right;
      margin-left: 0;
      .rs-table-cell-header-icon-sort {
        font-size: 10px;
        margin-right: 2px;
      }
    }
  }

  .rs-table-cell {
    cursor: pointer;
    
    .react-contextmenu-wrapper {
      display: inline-block;
      position: absolute;
    }
  }

  .rs-table-cell-content {
    padding: 0px 10px;  
    position: relative;
  }

  .rs-table-cell-expand-wrapper {
    width: 4px;
    margin-right: 8px;  
  }

  .rs-table-cell-last {
    border-right: none;
  }

  .negative {
		color: red;
	}
} 
#market_access_table {
  position: relative;

  .rs-table-row-header {
    outline: 1px solid $black;
  }

  .download-icon {
    position: absolute;
    z-index: 10;
    top: 0px;
    right: 1px;
    width: 24px;
  }
}

.rs-table .rs-table-row .rs-table-cell, 
.rs-table .rs-table-row .rs-table-cell-group {
  background-color: #fff;
}

.rs-table .rs-table-row.selected .rs-table-cell, 
.rs-table .rs-table-row.selected .rs-table-cell-group {
  background-color: #ffffe9;
  color: #6fa7d9;
  font-weight: bold;
}

.rs-table-hover .rs-table-row:hover .rs-table-cell, 
.rs-table-hover .rs-table-row:hover .rs-table-cell-group,
.rs-table-hover .rs-table-row:not(.rs-table-row-header):hover {
  background-color: #fffff4;
}

.rs-table-hover .rs-table-row.selected:hover .rs-table-cell, 
.rs-table-hover .rs-table-row.selected:hover .rs-table-cell-group, 
.rs-table-hover .rs-table-row.selected:hover {
  background-color: #ffffe9;
  color: #6fa7d9;
  font-weight: bold;
}

.select-view-menu {
  &.uk-dropdown {
    padding: 0;
  
  }
  min-width: 100px!important;
  width: 170px;
  border-radius: 5px;
  box-shadow: 1px 1px 1em black;
  left: 0 !important;
  
  .uk-container {
    padding: 0;
  }

  .cont-close {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 1.5rem;
    background-color: $black;
    color: whitesmoke;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    .uk-dropdown-close {
      margin-right: 5px;
      color: whitesmoke;
    }

    .uk-text-left {
      margin-left: 5px;
      font-weight: 600;
      font-size: 0.875rem;
    }

    &:before {
      display: none;
    }

    &:after {
      display: none;
    }
  }

  .cont-link {
    .a-style {
      display: flex;
      align-items: center;
      padding: 5px;
      cursor: pointer;

      // .left-icons,
      // .links {
      //   margin: 0 5px;
      // }

      .left-icons {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        color: white;
        cursor: pointer;
        width: 32px;
        height: 32px;
        // background-color: grey;
      }

      .links {
        color: $black;
        font-weight: 600;
      }

      &:hover {
        background-color: $lightblack;
      }
    }

    .uk-nav-divider {
      margin: 0;
    }
  }

  @media (min-width: 640px) {
    .uk-container {
      padding: 0;
    }
  }

  @media (min-width: 960px) {
    .uk-container {
      padding: 0;
    }
  }
}

.cog-menu {
  display: none;
  color: #757575;  
  position: absolute;
  right: 4px;
  top: -1px;
  z-index: 1;
  
  cursor: pointer;

  .table-icon, .get-icon {
    width: 18px;
    height: 18px;
  }
  .table-icon, .add-icon {
    margin: 0px 3px;
  }
  .add-icon {
    width: 16px;
    height: 16px;
  }
  .get-icon { 
    margin: 0px;
  }

  svg {
    margin: 1px; 
  }
}

.cog-menu-item {
  display: none!important;

  cursor: pointer;
  svg {
    position: absolute;
    top: -2px;
    right: 2px;
    width: 11px; 
    color: #34c3ff;
  }
}

@media only screen and (max-width : 1024px) {
  .cog-menu {
    display: block;
  }
}

.ReactModalPortal {
  .uk-dropdown {
    min-width: 160px;
    padding: 12px;
  }

  .metric-item {
    text-align: left;

    .uk-form-label {
      margin-bottom: 0;
    }
    .uk-input, .uk-select:not([multiple]):not([size]) {
      height: 23px;
      font-size: 12px;
    }

    a {
      font-size: .7rem;
    }
  }
}

.uk-tooltip {
  max-width: 30rem!important;
}
.right-panel {  
  .uk-search {
    margin-bottom: 6px;
    width: 100%;

    .uk-form-icon {
      width: 30px;
    }
    .uk-form-icon-close {
      left: unset;
      right: 58px;
      cursor: pointer;
      pointer-events: all;
    }
    .uk-input {
      width: calc(100% - 115px);
      height: 20px;
      padding: 0 20px;
    }

    .uk-form-icon:not(.uk-form-icon-flip)~.uk-input {
      padding-left: 30px!important;
    }
  }

  .clear-all {
    position: absolute;
    z-index: 1;
    right: 3px;
    top: 3px;
    color: red;
    font-size: 14px;
    opacity: .7;
    
    cursor: pointer;
    &:hover {
      opacity: 1;
      text-decoration: underline;
    }
  }

	.rp-header {
		width: 100%;
		margin-bottom: 5px;

		.uk-tab {
			margin: 0;
			justify-content: space-between;
		}

		.uk-tab > * {
			padding: 0;
		}

		.uk-tab > * > a {
			font-size: 10px;
			padding: 0;
      padding-bottom: 2px;
      padding-top: 3px;
      border-bottom: 2px solid #ffffff;
    }
    
    .uk-tab > .uk-active > a {
      color: #333;
      border-color: #1e87f0;
  }

		.uk-tab::before {
			border-bottom: none;
		}

		.uk-child-width-expand > :not([class*="uk-width"]) {
			min-width: 49px;
		}

		.rp-h-tabs,
		.rp-h-line {
			height: 25px;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		ul.rp-h-tabs {
			display: flex;
			font-size: 12px;
			margin: 0;

			li {
				width: 23%;
				float: left;
				margin-right: 2%;
				padding: 0 3px;
				border-radius: 4px;
				background-color: rgba(179, 185, 183, 0.308);
				cursor: pointer;
				text-align: center;
			}
		}

		.rp-h-line {
			font-size: 10px;
			font-style: italic;
		}
	}

	.rp-controls {
		.rp-container {
			.uk-accordion-item {
				margin-top: 2px;
				position: relative;

				.reset-filters-button {
					position: absolute;
					top: 4px;
					right: 25px;
					color: rgba(255, 0, 0, 0.6);
					cursor: pointer;

					&:hover {
						color: red;
					}
				}
			}

			.uk-accordion-title {
				font-size: 14px;
				font-weight: bold;

				.accordion-title-close {
					display: flex;
					justify-content: space-between;

					.uk-icon {
						display: flex;
					}
				}
			}

			.uk-accordion-content {
        margin-top: 2px;
        
        &.disabled {
          pointer-events: none;

          .controls-button {
            background-color: #f7f7f7!important;
            color: darkgray!important;
          }
        }

				.rp-buttons {
					display: flex;
					flex-wrap: wrap;
					overflow-y: auto;
					max-height: 300px;

					.rp-buttons-item {
            display: inline-block;
            text-align: center;
          }
          
          .align-left {
            text-align: left;
          }
        }
        
        .controls-button {
          background-color: #dce6f0;
          margin: 2px;
          padding: 0px 5px;
          font-size: 11px;
          border-radius: 2px;
          cursor: pointer;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          &.active {
            background-color: #566f8f;
            color: white;
          }

          &.disabled {
            pointer-events: none;
            background-color: #f7f7f7;
            color: darkgray;
          }
        }

        .month-item {
          cursor: pointer;
          user-select: none;
          
          &:not(.uk-card-secondary) {
            &:hover {
              background-color: #ebebeb;
            }
          }
        }
			}
		}
	}
}